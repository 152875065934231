import { DateTime } from "luxon";
import { CloseButton, Modal } from "react-bootstrap";
import PromoImage from '../../assets/images/2024SeptemberPromo.png';


// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const START_TIME = "2024-05-09T17:00:00.000Z";
// const END_TIME = "2024-05-16T23:00:00.000Z";

export function AnnouncementModal({ styleCss, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `fixthislater light-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className="pt-4 px-4">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">WHAT'S NEW</h5>
              <CloseButton onClick={props.onHide} />
            </div>
            <small>September 19th, 2024</small>
          </div>
          <div className={"card-body px-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <div className="d-flex">
              <img src={PromoImage} alt="banner" className="img-fluid mb-1 rounded-3 mx-auto" style={{ maxHeight: '250px' }}></img>
            </div>
            {/* <div className="rounded-3" style={{ width: '100%', overflow: 'hidden', maxHeight: '240px', position: 'relative' }}>
              <video className="rounded-3" style={{ width: '100%', height: '100%', transform: 'translate(0, -10%)' }} autoPlay playsInline>
                <source src="https://i.imgur.com/yANeMmt.mp4" type="video/mp4" />
              </video>
            </div> */}
            {/* <div className="d-flex justify-content-between mt-3 text-primary">
              <h6>SHOP&nbsp;IS&nbsp;LIVE&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Spend your points @ <a href="https://shop.degencoinflip.com" target="_blank" rel="noopener noreferrer"><b>shop.degencoinflip.com</b></a> and claim RFFs and other rewards.</li>
            </ul> */}
            <div className="d-flex justify-content-between mt-3 text-success">
              <h6>Mount&nbsp;Olympus:&nbsp;The&nbsp;Grand&nbsp;Awakening</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>September 19th - September 26th</li>
              <li>As the sacred gates of Mount Olympus open for the first time in millennia, cats from all factions are called to scale its mystical slopes, facing divine trials and collecting celestial artifacts.</li>
              <li>The Degen Coin Shop @ <a href="https://shop.degencoinflip.com" rel="noreferrer" target="_blank">shop.degencoinflip.com</a> has been reopened! Earn points as you play any DCF games.</li>
              {/* <li>Access:</li>
                <ul>
                  <li>App is in beta, admitting 1,000 users at a time</li>
                  <li>Check your waitlist position on the site</li>
                  <li>Opens to public after waitlist</li>
                  <li>Eligible waitlist members get risk-free plays, sign in to see</li>
                </ul> */}

              {/* <br/>Start: {DateTime.fromISO(START_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}
                 <br/>End: {DateTime.fromISO(END_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}
                <br/>@ <a href="https://shop.degencoinflip.com" rel="noreferrer" target="_blank">shop.degencoinflip.com</a> */}
            </ul>

            <div className="d-flex justify-content-between mt-3 text-primary">
              <h6>POWER&nbsp;HOURS&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Bonus: Power Hours - During Power Hours players will earn double the amount of points.</li>
              <li>Power Hour 1: {DateTime.utc().set({ hour: 0, minute: 0 }).toLocal().toLocaleString(DateTime.TIME_SIMPLE)} ~ {DateTime.utc().set({ hour: 1, minute: 0 }).toLocal().toLocaleString(DateTime.TIME_SIMPLE)} (00:00–01:00 UTC)</li>
              <li>Power Hour 2: {DateTime.utc().set({ hour: 12, minute: 0 }).toLocal().toLocaleString(DateTime.TIME_SIMPLE)} ~ {DateTime.utc().set({ hour: 13, minute: 0 }).toLocal().toLocaleString(DateTime.TIME_SIMPLE)} (12:00–13:00 UTC)</li>
            </ul>

            <div className="d-flex justify-content-between mt-3 text-warning">
              <h6>POINTS&nbsp;SYSTEM&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Accumulate points as you play! Everlasting and ready for future DCF ecosystem surprises.</li>
            </ul>

            <div className="d-flex justify-content-between mt-3 text-danger">
              <h6>FUTURE&nbsp;RELEASES&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>We have new updates coming for DCF this year 2024. Stick around and find out. <a href="https://twitter.com/degencoinflip" rel="noreferrer" target="_blank">Twitter</a> & <a href="https://discord.gg/degencoinflip" rel="noreferrer" target="_blank">Discord</a>.</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
