import { DateTime } from 'luxon';
import { createContext, useEffect, useState } from "react";
import { getDailyPowerHours } from "../api/atlantis.service";
import { loadState, saveState } from '../utils/localStorage';

const POWER_HOUR_CACHE = 'powerhour';
const COIN_SPINNING_IMAGE_URL = 'https://i.imgur.com/hIFS6wF.gif';

interface PowerHourContextValue {
    powerHourActive: boolean | any;
    backgroundImageUrl: string | any;
    coinSpinningImageUrl: string | any;
    // backgroundMobileImageUrl: string | any;
    fetchTodayPowerHours(): void;
}

const PowerHoursContext = createContext<PowerHourContextValue>({
    powerHourActive: false,
    coinSpinningImageUrl: COIN_SPINNING_IMAGE_URL,
    backgroundImageUrl: '../assets/images/2024SeptemberPromo.png',
    // backgroundMobileImageUrl: '',
    fetchTodayPowerHours() { },
});

const PowerHoursProvider = (props: any) => {
    const [powerHourActive, setPowerHourActive] = useState<boolean>(false);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>();

    useEffect(() => {
        checkPowerHours();
        const intervalId = setInterval(() => {
            checkPowerHours();
        }, 60 * 1000); // Check every minute
        return () => clearInterval(intervalId); // Cleanup on unmount
        // eslint-disable-next-line
    }, []);

    const checkPowerHours = async () => {
        const cache = loadState(POWER_HOUR_CACHE);
        console.log("check power hours");
        if (cache) {
            console.log("checked in cache");
            const cachedData = JSON.parse(cache); // Parse the string back into an object/array
            const powerHour = isPowerHourActive(cachedData);
            setPowerHourActive(!!powerHour);
            setBackgroundImageUrl(powerHour?.backgroundImageUrl || null);
        } else {
            console.log("not checked in cache, calling fetchtodaypowerhours");
            fetchTodayPowerHours();
        }
    };

    const fetchTodayPowerHours = async () => {
        try {
            const response = await getDailyPowerHours();
            console.log(response);
            const powerHour = isPowerHourActive(response);
            console.log(powerHour);
            if (powerHour) {
                saveState(JSON.stringify(response), POWER_HOUR_CACHE);
                setPowerHourActive(true);
                setBackgroundImageUrl(powerHour.backgroundImageUrl || null);
            } else {
                setPowerHourActive(false);
            }

        } catch (error) {
            console.error("Error fetching power hours:", error);
            setPowerHourActive(false); // Default to false if there's an error
        }
    };

    const isPowerHourActive = (powerhours: any) => {
        if (!powerhours || powerhours.length === 0) return null;
        // Get the current time in UTC
        const now = DateTime.utc();
        // Check if any power hour is currently active
        const activePowerHour = powerhours.some((powerHour: any) => {
            const startTime = DateTime.fromISO(powerHour.startTime, { zone: 'utc' });
            const endTime = DateTime.fromISO(powerHour.endTime, { zone: 'utc' });

            // Compare the current time with the power hour's start and end times
            return now >= startTime && now <= endTime;
        });
        return activePowerHour ?? null;
    };

    return (
        <PowerHoursContext.Provider value={{ powerHourActive, backgroundImageUrl, fetchTodayPowerHours, coinSpinningImageUrl: COIN_SPINNING_IMAGE_URL }}>
            {props.children}
        </PowerHoursContext.Provider>
    );
};

export { PowerHoursContext, PowerHoursProvider };